@import './app-variables';

.mat-button-wrapper {
  text-transform: capitalize;
}

.mat-dialog-content {
  .mat-input-element,
  .mat-select-value-text * {
    color: $foundational-color-10;
    font-size: $base-font-size-web;
  }
}

.mat-form-field-suffix {
  // align-self: end;
}

.mat-form-field-disabled .mat-form-field-wrapper {
  background-color: $brand-color-3;
}

.mat-form-field-infix {
  min-height: 29px;
}

.mat-form-field-disabled,
.input-employee {
  .mat-form-field-wrapper {
    border: 1px solid $brand-color-3 !important;
    .mat-form-field-infix {
      padding-left: 0 !important;
      padding-right: 0 !important;
      &:has(input[hidden]){
        padding-left: 0.5em !important;
      }
      &:has(mat-input:disabled){
        padding-left: 0.5em !important;
      }
      &:has(mat-select.mat-select-disabled){
        padding-left: 0.5em !important;
      }
      xpo-icon {
        margin-left: 0.15em;
      }
    }
  }

  .mat-select-value {
    color: $text-color-dark-1 !important;
  }

  .mat-select-arrow-wrapper {
    opacity: 0;
  }

  .mat-form-field-suffix {
    display: none;
  }
}

input.mat-input-element {
  color: $text-color-dark-1 !important;
}

textarea.mat-input-element {
  color: $text-color-dark-1 !important;
}

mat-form-field.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
}
mat-form-field {
  .mat-input-element:disabled {
    color: $foundational-color-12 !important;
    font-weight: $text-weight-rg;
  }
  mat-select.mat-select-disabled {
    span.mat-select-value-text {
      color: $foundational-color-12 !important;
      font-weight: $text-weight-rg;
    }
  }
  .mat-form-field-required-marker {
    color: #D50000;
  }
}
mat-divider {
  color: $foundational-color-13;
}
.mat-menu-content {
  .mat-menu-item {
    color: rgba(0, 0, 0, 0.87);
    height: 48px !important;
    line-height: 48px !important;
    padding: 0 16px !important;
  }
}

.mat-datepicker-toggle .mat-mdc-icon-button .mat-icon {
  line-height: 16px!important;
}

.upload-container--upload {
  .xpo-Upload-progress {
    display: none !important;
  }
}

mat-form-field.mat-form-field-type-mat-input .mat-form-field-infix .mat-icon + .mat-input-element {
  padding-left: 24px !important;
}

.xpo-FilterContainer-optionsContainer {
  overflow-x: hidden;
}

.xpo-SaveViewDialog .xpo-DialogContent {
  mat-form-field.mat-form-field-appearance-legacy .mat-form-field-label {
    width: auto;
  }
}

.mat-form-field.mat-form-field-appearance-legacy, .address-entry-container mat-form-field.mat-form-field-type-mat-input {
  margin-bottom: 24px;
}

mat-form-field.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 10px!important;
  position: absolute!important;
}

.mat-form-field.mat-form-field-appearance-legacy:not(.mat-form-field-should-float) .mat-form-field-label{
  padding-left: 10px;
}